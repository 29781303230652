import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = ({ data }) => {
  const { html, frontmatter } = data.file.childMarkdownRemark;
  return (
    <Layout>
      <SEO title="Contact Us"/>
      <section className="hero is-light is-medium is-banner contact-us">
        <div className="background-image"/>
      </section>
      <section className="section">
        <div className="container">
            <h1 className="title">{frontmatter.headline}</h1>
            <div className="columns">
                <div className="column is-two-thirds content">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.9035371201476!2d-117.87667678490085!3d33.60780724846714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dce08bc8596337%3A0xf2dcfe4df36fc821!2sJupiter%20Holdings!5e0!3m2!1sen!2sus!4v1578874645310!5m2!1sen!2sus" frameborder="0" style={{width: "100%", height: 450, backgroundColor: "whitesmoke"}} allowfullscreen=""></iframe>
                </div>
                <div className="column content" dangerouslySetInnerHTML={{ __html: html }}/>
            </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ContactUsQuery {
    file(name: {eq: "contact"}) {
      childMarkdownRemark {
        frontmatter {
          headline
        }
        html
      }
    }
  }
`

export default ContactPage
